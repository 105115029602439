const SampleCons = {
  PRESUMED_SUBSTANCES: [
    "Carfentanil",
    "Cocaine",
    "Fentanyl",
    // "Heroin",
    "Isotonitazene",
    // removed Ketamine
    "MDMA",
    "Methamphetamine",
    "Other",
  ],
  TRACE_SUPPORTED: [
    "Carfentanil",
    "Cocaine",
    "Fentanyl",
    "Isotonitazene",
    "Methamphetamine",
    "Other",
  ],
  NEW_PRESUMED_SUBSTANCES: [
    {
      label: "Opiods",
      drugs: [
        "Carfentanil",
        "Fentalog (Fentanyl, Fluorfentanyl, etc.)",
        "Heroin",
        "Nitazene",
      ],
    },
    {
      label: "Stimulants",
      drugs: ["Cocaine", "Methamphetamine", "MDMA/MDA"],
    },
    {
      label: "Depressants",
      drugs: ["Benzodiazepines (Bromazalom, Flubromazolam, Clonazepam, etc.)"],
    },
    {
      label: "Dissociative Drugs",
      drugs: ["Ketamine", "Phencyclidine (PCP)", "Dextromethorphan (DXM)"],
    },
    {
      label: "Prescription Medications", //update any changes to this list in Body.js too
      drugs: [
        "Adderall®",
        "Ativan®",
        "Dilaudid®",
        "Klonopin®",
        "OxyContin®",
        "Ritalin®",
        "Valium®",
        "Vyvanse®",
        "Xanax®",
      ],
    },
    {
      label: "Other Compounds",
      drugs: ["Xylazine", "Other"],
    },
  ],

  ANALYTE_NAMES: [
    {
      modelName: "FUB-AMB",
      displayName: "AMB-FUBINACA (FUB-AMB)",
    },
    {
      modelName: "MDMA",
      displayName: "3-4 Methylenedioxymethamphetamine (MDMA)",
    },
    {
      modelName: "MSM",
      displayName: "Methylsulfonylmethane (MSM)",
    },
    {
      modelName: "MorphineTab",
      displayName: "Morphine Tablet",
    },
    {
      modelName: "PCP",
      displayName: "Phencyclidine (PCP)",
    },
    {
      modelName: "PEG",
      displayName: "Polyethylene Glycol (PEG)",
    },

    {
      modelName: "GHB",
      displayName: "Gamma-Hydroxybutyrate (GHB)",
    },
    {
      modelName: "BagBlue",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BagPink",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BagTransparent",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BagYellow",
      displayName: "Plastic Bag",
    },
    {
      modelName: "BakingSoda",
      displayName: "Baking Soda",
    },

    {
      modelName: "LGlutamine",
      displayName: "L-Glutamine",
    },
    {
      modelName: "4ANPP",
      displayName: "4-ANPP",
    },
    {
      modelName: "BG",
      displayName: "Chip Background",
    },
    {
      modelName: "ABFubinica",
      displayName: "AB-Fubinaca",
    },
  ],
};

export default SampleCons;
