import axios from "axios";
import { useQuery } from "react-query";
import { commonDrugs, adverseReactions } from "./logic";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

export function postProcessSpectra(obj) {
  return axios.post("/companion/view-edit-sample/process-spectra", obj);
}
export function useCommonDrugs(ldate, drug, selectedLocation) {
  return useQuery(["byLoc", "commonDrugs", ldate, drug, selectedLocation], () =>
    commonDrugs(ldate, drug, selectedLocation)
  );
}

export function useAdverseReactions(ldate, substance) {
  return useQuery(["byLoc", "adverseReactions", ldate, substance], () =>
    adverseReactions(ldate, substance)
  );
}
